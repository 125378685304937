import { BrowserRouter, Route, Routes, Outlet, Navigate } from 'react-router-dom';
import Header from './Component/CommonComponent/Header';
import Footer from './Component/CommonComponent/Footer';
import DefaultLayout from "./Component/CommonComponent/DefaultLayout";
import Login from './Component/Login';
import Register from './Component/Register';
import Forgot from './Component/Forgot';
import Thankyou from './Component/Thankyou';
import SetPassword from './Component/SetPassword';
import OneSourceFinal from './Component/OneSourceFinal';
import GotMail from './Component/GotMail';
import Octapharma from './Component/Octapharma';
import DocintelPrivacy from './Component/DocintelPrivacy';
import Redirect from './Component/Redirect';
import PageNotFound from './Component/PageNotFound';
import Term from './Component/Term';
import React, { useEffect, useRef, useState } from 'react';
import ScrollContext from './Component/ScrollContext';
import EAHAD2024 from './Component/EAHAD2024';
import EAHAD2025 from './Component/EAHAD2025';
import ISTH2024 from './Component/ISTH2024';
import ISTH2 from './Component/ISTH2';
import WFH2024 from './Component/WFH2024';
import AlandInvitationEventProtection from './Component/AlandInvitationEventProtection';
import "react-loading-skeleton/dist/skeleton.css";
import { loader } from './Component/CommonComponent/Loader';


const Layout = () => {
  const isAuthenticated = localStorage.getItem("un") !== null;
  const highlightRef = useRef(null);
  const expertOpinionRef = useRef(null);
  const yourOpinionRef = useRef(null);
  const eventRef = useRef(null);
  const mostPopularContentRef = useRef(null);
  const oneSourceLibraryRef = useRef(null);
  const podcastRef = useRef(null);
  const webinarRef = useRef(null);
  const logoutBtnRef1 = useRef(null);
  const logoutBtnRef2 = useRef(null);
  const symposiumRef = useRef(null);
  const [shouldObserveElements, setShouldObserveElements] = useState(false);

  return (

    <>
      {
        isAuthenticated ?
          <ScrollContext.Provider
            value={{
              highlightRef,
              expertOpinionRef,
              yourOpinionRef,
              eventRef,
              mostPopularContentRef,
              oneSourceLibraryRef,
              podcastRef
              , webinarRef,
              shouldObserveElements, setShouldObserveElements, logoutBtnRef1, logoutBtnRef2, symposiumRef
            }}
          >
            <Header />
            <Outlet />
            <Footer />
          </ScrollContext.Provider>
          : <Navigate to="/login" />
      }

    </>
  )
}

function App() {


  useEffect(() => {
    const removeLoader = () => {
      loader("hide")

    }
    document.addEventListener("DOMContentLoaded", removeLoader)


    return () => {
      document.removeEventListener("DOMContentLoaded",removeLoader)
    }

  }, [])
  return (
    <>

      <BrowserRouter>

        <Routes>
          <Route path="/" element={<DefaultLayout component={Login} />} />
          <Route path="/login" element={<DefaultLayout component={Login} />} />
          <Route path="/register" element={<DefaultLayout component={Register} />} />
          <Route path="/forgot" element={<DefaultLayout component={Forgot} />} />
          <Route path="/thankyou" element={<Thankyou />} />
          <Route path="/set-password" element={<SetPassword />} />
          <Route path="/got-mail" element={<GotMail />} />
          <Route path="/redirect" element={<Redirect />} />
          <Route path="/octapharma-privacy" element={<Octapharma />} />
          <Route path="/aland-data-protection" element={<AlandInvitationEventProtection />} />
          <Route path="/terms_of_use" element={<Term />} />
          <Route path="/docintel-privacy" element={<DocintelPrivacy />} />
          <Route path="/EAHAD2024" element={<EAHAD2024 />} />
          <Route path="/EAHAD2025" element={<EAHAD2025 />} />
          <Route path="/WFH2024" element={<WFH2024 />} />
          <Route path="/ISTH2024" element={<ISTH2024 />} />
          <Route path="/ISTH2024_2" element={<ISTH2 />} />


          <Route path="/home" element={<Layout />}>
            <Route index element={<OneSourceFinal />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
