import React, { useEffect } from 'react';
import {Container, Row} from 'react-bootstrap'
import { loader } from "./CommonComponent/Loader";
const EAHAD2025 = () => {
    useEffect(() => {
        loader("hide")
     }, []);
  
  return (
    <>
        <div className="promotion-page new">
            <Container>
                <Row>
                    <div className="promotion-section">
                        <h1>UNDER CONSTRUCTION</h1>
                        <h3>We're building a Octapharma EAHAD 2025 page here - can't wait to show it to you.</h3>
                        <img src="https://docintel.app/img/octa/e-templates/one-source/onesource-logo.gif" alt="One Source"/>
                    </div>
                </Row>
            </Container>
        </div>
    </> 
  )
}

export default EAHAD2025